import { GET_REQUEST, POST_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import router from '@/router';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import { checkPastDate } from '@lemontree-ai/lemontree-admin-common-front/utils/dateUtils';

export default class BannerSourceListViewModel {
  constructor() {
    this.boardData = {
      title:'베너소스목록',
      drawDataList: [
        {
          title:'No.',
          width:'50px',
          value:'id',
        },
        {
          title:'베너섬네일',
          width:'120px',
          value:'bannerImageUrl',
          img:{
            size:'contain',
            width:'105px',
            height:'55px',
            position:{
              x:'center',
              y:'center',
            }
          },
        },
        {
          title:'제목',
          width:'',
          value:'title',
          isAlignLeft: true,
          emptyValueText:'⚠️',
          filter: {
            name:'convertLineFeed'
          },
        },
        {
          title:'누적클릭수',
          width:'80px',
          value:'viewCount',
          isAlignLeft: true,
          emptyValueText:'⚠️'
        },
        {
          title:'타겟구분',
          width:'94px',
          value:'target',
          filter: {
            name:'convertIdToText',
            value:'user_target'
          },
          class: {
            classColorFilter: {
              name: 'convertIdToColor',
              dataListName: 'user_target',
              prev: 'tc',
            },
          },
          isAlignLeft: true,
          emptyValueText:'⚠️'
        },
        {
          title:'게시기간',
          width:'194px',
          value:'',
          dateRangeValue:{
            startValue: 'startAt',
            endValue: 'endAt',
            betweenText: ' ~ ',
            isEndPastDateToRed: true
          },
          isAlignLeft: true,
          emptyValueText:'⚠️'
        },
      ],
      option:{
        isTotal: true,
        isSize: true,
      }
    };
    this.dataList = [];
    this.searchParams = {
      page:0,
      pageSize:10,
      direction: 'DESC'
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };
  }

  init(query){
    const { pageSize, page } = query;
    if(pageSize) {
      this.searchParams.pageSize = Number(pageSize);
    }
    if(page) {
      this.searchParams.page = Number(page);
    }
    this.getSourceListData();
  }
  onClickRow(rowId,rowData){
    router.push({
      name: 'APP_BANNER_SOURCE_MODIFY',
      params: { id : rowId},
      query : {
        pageSize : this.searchParams.pageSize,
        page : this.searchParams.page,
      }
    });
  }
  onSearch(){
    this.getSourceListData();
  }
  onClickDelete(){
    const requestDelete = () => {
      router.push({ name: 'APP_TERMS_LIST' });
      store.dispatch('commonToast/fetchToastStart', `약관이 삭제되었습니다.`);
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `약관을 정말 삭제하시겠습니까?`,
      closeBtnText: '취소',
      compeleteBtnText: '확인',
      confirmCallBack: requestDelete,
    });
  }
  
  onClickCompelete(){
    let toastMessage = '';
    let routeName = '';
    if(this.isModify){
      toastMessage = '수정';
      routeName = 'APP_TERMS_DETAIL';
    }else{
      toastMessage = '등록';
      routeName = 'APP_TERMS_LIST';
    }
    store.dispatch('commonToast/fetchToastStart', `약관 ${toastMessage}이 완료되었습니다.`);
    router.push({ name: routeName });
  }

  getTermDetail(id){
    const resultData = {id:id, title:'약관 제목이 들어갑니다.', content:'약관 내용이 들어갑니다. 약관 내용이 들어갑니다. 약관 내용이 들어갑니다.', isFixed:true}
    this.model.setData(resultData);
  }


  getSourceListData(){
    const query = makeQueryStringByObject(this.searchParams);
    const path = `${apiPath.BANNER_SOURCE_LIST}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.dataList = resultData.data.map(item => {
        item.disabled = Boolean(checkPastDate( item.endAt, 'yesIsPast' ))
        return item
      })
      this.paginationData.totalCount = resultData.total;
      this.paginationData.totalPage = resultData.totalPage;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}